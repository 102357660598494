<!--
 * @Description: 品质管理-检查任务-查看检查项
 * @Author: 马俊
 * @Date: 2021-04-27 11:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="qualityInspectionView-wrapper">
    <list
      ref="list"
      :exportPermission="exportPermission"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :exportMethod="exportMethod"
      :searchParams="searchParams"
      :isMultiSelect="false"
      :hasOperateColumn="false"
      :headers="headers"
    >
      <template #headerSlot>
        <!-- <v-button text="新增" permission="add" @click="create"></v-button> -->
        <v-button text="返回" @click="goBack"></v-button>
      </template>

      <template #searchSlot>
        <v-input label="检查项名称" v-model="searchParams.itemName" />
        <!-- <v-select2 label="检查对象" v-model="searchParams.communityId" v-bind="communityParams" /> -->
        <!-- <v-select label="检查负责人" v-model="searchParams.executeUserId" :options="executeUserOps"/> -->
        <v-input label="检查负责人" v-model="searchParams.executeUserName" />
        <v-datepicker
          type="rangedatetimer"
          label="检查时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.executeTimeStart"
          :endTime.sync="searchParams.executeTimeEnd"
        />
      </template>
    </list>
  </div>
</template>

<script>
import {
  getQualityCheckPlanTaskItemListURL,
  exportQualityCheckPlanTaskItemListURL
} from './api'
import {
  // statusMap,
  // statusOps,
  checkScoreMap
  // checkScoreOps
} from './map'
import { communityParams } from 'common/select2Params'
import { createImgVNode } from 'common/vdom'

export default {
  name: 'qualityInspectionView',
  data () {
    const _this = this
    return {
      searchUrl: getQualityCheckPlanTaskItemListURL,
      exportUrl: exportQualityCheckPlanTaskItemListURL,
      exportMethod: 'get',
      exportPermission: 'export2',
      executeUserOps: [],
      searchParams: {
        taskId: '',
        itemName: '',
        communityId: '',
        executeUserId: '',
        executeUserName: '',
        executeTimeStart: '',
        executeTimeEnd: ''
      },
      communityParams,
      headers: [
        {
          prop: 'itemName',
          label: '检查项名称'
        },
        {
          prop: 'itemCriterion',
          label: '检查标准'
        },
        {
          prop: 'itemDescription',
          label: '检查方法及判定细则'
        },
        {
          prop: 'communityName',
          label: '检查对象'
        },
        {
          prop: 'executeTime',
          label: '检查时间'
        },
        {
          prop: 'executeUserName',
          label: '检查人'
        },
        {
          prop: 'checkScore',
          label: '检查得分',
          formatter (row) {
            if (!row.executeUserId) { // 任务执行状态为未开始时，返回空
              return ''
            }
            // displayModel 1 按评分 2 按是否合格
            if (row.displayModel === 1) {
              return row.checkScore
            }
            return checkScoreMap[row.checkScore] || ''
          }
        },
        {
          prop: 'checkDetail',
          label: '检查详情'
        },
        {
          prop: 'scenePhotos',
          width: 220,
          label: '现场照片',
          formatter (row, prop) {
            let nodeList = []
            row[prop] && row[prop].split(',').forEach(imgUrl => {
              nodeList.push(createImgVNode(_this, row, prop, {
                imgUrl,
                style: {
                  marginRight: '2px'
                }
              }))
            })
            const VNode = _this.$createElement('div', null, nodeList)
            return VNode
          }
        }
      ]
    }
  },
  created () {
    this.searchParams.taskId = this.$route.query.taskId
    // this.searchParams.communityId = this.$route.query.communityId
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
